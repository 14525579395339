import React from "react";
import VaccinationList from "./VaccinationList";
import { GroupBy } from "../../_shared/shared.functions";

export default function VaccinationLists({ vaccinations }) {
  const vaccinationGroups = GroupBy(vaccinations, (i) => i.VaccineProgramId);
  const vaccinePrograms = Object.keys(vaccinationGroups);

  return (
    <>
      {vaccinations?.length ? (
        <>
          {vaccinePrograms
            .sort((a, b) => parseFloat(a) - parseFloat(b))
            .map((v, i) => (
              <div key={i}>
                <div className="mb-2">
                  <h3>
                    {vaccinationGroups[v]?.[0]?.VaccineProgramName} vaccination
                    history
                  </h3>
                  <VaccinationList
                    vaccinations={vaccinationGroups[v]}
                  ></VaccinationList>
                </div>
                <br />
                <br />
              </div>
            ))}
        </>
      ) : (
        <h3>No vaccination history available</h3>
      )}
    </>
  );
}
