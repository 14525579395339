import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NhsActionLink from "../../_shared/components/NHSUK/NhsActionLink";
import { IsoToDateFornat } from "../../_shared/shared.functions";
import { toTitleCase } from "../../_shared/shared.functions";
import userService from "../user/user.service";

const displayCount = 1;

export const dataSourceDisplayNames: { [key: string]: string } = {
  RAVS: "RAVS",
  NHSE: "NHS England",
};

export default function VaccinationList({ vaccinations }) {
  const [user, setUser] = useState(userService.getUser());
  const navigate = useNavigate();
  const [count, setCount] = useState(displayCount);

  useEffect(() => {
    if (!user) getUser();
  });

  const getUser = async () => {
    setUser(await userService.getUser$());
  };

  let items = vaccinations;
  if (items?.length > count) {
    items = vaccinations.slice(0, count);
  }

  return (
    <>
      {items?.length ? (
        <div>
          <p>
            Displaying{" "}
            {vaccinations.length > displayCount ? count : vaccinations.length}{" "}
            of {vaccinations.length}
          </p>

          {items.map((i) => (
            <div key={i.VaccinationId} className="nhsuk-card mb-1">
              <div className="nhsuk-card__content">
                <dl className="nhsuk-summary-list mb-1">
                  <div className="nhsuk-summary-list__row">
                    <dt className="nhsuk-summary-list__key">Vaccine</dt>
                    <dd className="nhsuk-summary-list__value">
                      <div>{i.VaccineProgramName} </div>
                    </dd>
                  </div>

                  <div className="nhsuk-summary-list__row">
                    <dt className="nhsuk-summary-list__key">Date</dt>
                    <dd className="nhsuk-summary-list__value">
                      <div>{IsoToDateFornat(i.VaccinationDate)} </div>
                    </dd>
                  </div>

                  <div className="nhsuk-summary-list__row">
                    <dt className="nhsuk-summary-list__key">Product name</dt>
                    <dd className="nhsuk-summary-list__value">
                      {i.VaccineName}
                    </dd>
                  </div>

                  <div className="nhsuk-summary-list__row">
                    <dt className="nhsuk-summary-list__key">Batch number</dt>
                    <dd className="nhsuk-summary-list__value">
                      {i.BatchNumber}
                    </dd>
                  </div>

                  <div className="nhsuk-summary-list__row">
                    <dt className="nhsuk-summary-list__key">Site</dt>
                    <dd className="nhsuk-summary-list__value">
                      {toTitleCase(i.SiteName)}
                    </dd>
                  </div>

                  <div className="nhsuk-summary-list__row">
                    <dt className="nhsuk-summary-list__key">Data source</dt>
                    <dd className="nhsuk-summary-list__value">
                      {dataSourceDisplayNames[i.DataSource] || i.DataSource}
                    </dd>
                  </div>
                </dl>
                {i.VaccinationId &&
                  user &&
                  user.OrganisationId == i.OrganisationId && (
                    <div>
                      <div className="float-start">
                        <NhsActionLink
                          text={"Delete"}
                          onClick={() => {
                            navigate(`/vaccination/delete/${i.VaccinationId}`);
                          }}
                        ></NhsActionLink>
                      </div>
                      <div className="float-end">
                        <NhsActionLink
                          text={"Edit"}
                          onClick={() => {
                            navigate(`/vaccination/edit/${i.VaccinationId}`);
                          }}
                        ></NhsActionLink>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          ))}

          {vaccinations.length > displayCount && (
            <div>
              {count !== vaccinations.length ? (
                <>
                  &nbsp;
                  <button
                    className="nhs-link-button float-end"
                    onClick={() => setCount(vaccinations.length)}
                  >
                    Show all ({vaccinations.length})
                  </button>
                </>
              ) : (
                <>
                  &nbsp;
                  <button
                    className="nhs-link-button float-end"
                    onClick={() => setCount(displayCount)}
                  >
                    Hide
                  </button>
                </>
              )}
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
