import * as React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import { Button } from "reactstrap";
import useDocumentTitle from "../../_shared/hooks/useDocumentTitle";
import useAnalytics from "../analytics/hooks/useAnalytics";
import {
  AddBatchAnalyticsSubCategories,
  SiteVaccinesAnalyticsPageNames,
  SiteVaccinesContants,
  SiteVaccinesPageTitles,
  SiteVaccinesPaths,
} from "./site-vaccines.enums";
import {
  HandleErrorFocusOnClickByName,
  Redirect,
  RemoveTime,
} from "../../_shared/shared.functions";
import { Paths } from "../../_shared/shared.enums";
import { SiteVaccines } from "./site-vaccines.models";
import { BatchDto, ExistingSiteVaccineBatch } from "../batch/batch.models";
import siteVaccinesService from "./site-vaccines.service";

export default function AddEditBatch() {
  const location = useLocation();
  const navigate = useNavigate();

  const redirect = () => {
    Redirect(Paths.Home);
  };

  const optionsStateData =
    location && location.state ? (location.state[0] as any) : redirect();
  const userSitesStateData =
    location && location.state ? (location.state[1] as any) : redirect();
  const siteVaccinesStateData =
    location && location.state
      ? (location.state[2] as SiteVaccines[])
      : redirect();
  const batchStateData =
    location && location.state ? (location.state[3] as BatchDto) : redirect();
  const siteVaccineBatchesStateData =
    location && location.state ? (location.state[4] as BatchDto[]) : redirect();
  const addOrEditStateData =
    location && location.state ? (location.state[5] as any) : redirect();
  const depleteOrUndepleteStateData =
    location && location.state ? (location.state[6] as any) : redirect();
  const siteVaccineStateData =
    location && location.state
      ? (location.state[7] as SiteVaccines)
      : redirect();

  const [options, setOptions] = React.useState(optionsStateData as any);
  const [userSites] = React.useState(userSitesStateData);
  const [siteVaccines, setSiteVaccines] = React.useState(
    siteVaccinesStateData as SiteVaccines[],
  );
  const [batch, setBatch] = React.useState(batchStateData as BatchDto);
  const [siteVaccineBatches, setSiteVaccineBatches] = React.useState(
    siteVaccineBatchesStateData as BatchDto[],
  );
  const [addOrEdit] = React.useState(addOrEditStateData as any);
  const [depleteOrUndeplete] = React.useState(
    depleteOrUndepleteStateData as any,
  );
  const [siteVaccine] = React.useState(siteVaccineStateData as SiteVaccines);

  const [existingSiteVaccineBatch, setExistingSiteVaccineBatch] =
    React.useState(null as ExistingSiteVaccineBatch);

  let pageAction = SiteVaccinesPageTitles.AddBatch;
  pageAction = addOrEdit?.IsEditBatch
    ? SiteVaccinesPageTitles.EditBatch
    : pageAction;
  useDocumentTitle(pageAction);

  let subCategory2 = AddBatchAnalyticsSubCategories.SubCategory2Add;
  subCategory2 = addOrEdit?.IsEditBatch
    ? AddBatchAnalyticsSubCategories.SubCategory2Edit
    : subCategory2;
  useAnalytics([
    "service",
    SiteVaccinesAnalyticsPageNames.PrimaryCategory,
    AddBatchAnalyticsSubCategories.SubCategory1,
    subCategory2,
  ]);

  const [showInvalidBatchFormatWarning, setShowInvalidBatchFormatWarning] =
    React.useState(false);
  const [showOtherBatchNumberField, setShowOtherBatchNumberField] =
    React.useState(true);
  const [batchExistsError, setBatchExistsError] = React.useState(false);

  const [otherBatchNumberRequired, setOtherBatchNumberRequired] =
    React.useState(false);

  const [batchExpiryDateRequiredError, setBatchExpiryDateRequiredError] =
    React.useState(false);
  const [batchExpiryDateError, setBatchExpiryDateError] = React.useState(false);
  const [futureExpiryDateError, setFutureExpiryDateError] =
    React.useState(false);

  let batchExpiryDate =
    batch && batch?.ExpiryDate?.includes("T")
      ? RemoveTime(batch?.ExpiryDate)
      : batch && !batch?.ExpiryDate?.includes("T")
        ? batch?.ExpiryDate
        : "";

  function CheckOtherBatchNumberExistsInSite(
    vaccineId: number,
    site: string,
    otherBatchNumberValue: string,
    siteVaccineBatches: BatchDto[],
  ) {
    siteVaccineBatches =
      addOrEdit && addOrEdit?.IsEditBatch
        ? siteVaccineBatches.filter(
            (svb) =>
              svb.BatchNumber?.toUpperCase() !==
              batch?.BatchNumber?.toUpperCase(),
          )
        : siteVaccineBatches;

    siteVaccineBatches?.forEach((siteVaccineBatch: BatchDto) => {
      if (
        siteVaccineBatch.VaccineId === vaccineId &&
        siteVaccineBatch.Site === site &&
        siteVaccineBatch.BatchNumber?.toUpperCase() === otherBatchNumberValue
      ) {
        setBatchExistsError(true);
      }
    });
  }

  const formik = useFormik({
    initialValues: {
      VaccineProgramId: batch?.VaccineProgramId.toString(),
      OtherBatchNumber: batch?.BatchNumber?.toUpperCase(),
      ExpiryDate_1: batch ? batchExpiryDate?.split("-")[2] : "",
      ExpiryDate_2: batch ? batchExpiryDate?.split("-")[1] : "",
      ExpiryDate_3: batch ? batchExpiryDate?.split("-")[0] : "",
    },

    enableReinitialize: true,

    onSubmit: async (values) => {
      setOtherBatchNumberRequired(false);

      setBatchExpiryDateRequiredError(false);
      setBatchExpiryDateError(false);
      setFutureExpiryDateError(false);

      if (!batchExistsError) {
        let batchData = values as unknown as BatchDto;

        batchData = batch ? batch : batchData;

        let otherBatchNumberRequiredError = false;

        let expiryDateErrorRequired = false;
        let expiryDateError = false;
        let expiryDateFutureError = false;

        if (
          !values.OtherBatchNumber ||
          values.OtherBatchNumber.toUpperCase() === "UNDEFINED"
        ) {
          otherBatchNumberRequiredError = true;
        }

        if (
          !values.ExpiryDate_1 &&
          !values.ExpiryDate_2 &&
          !values.ExpiryDate_3
        ) {
          expiryDateErrorRequired = true;
        }

        if (values.ExpiryDate_1 || values.ExpiryDate_2 || values.ExpiryDate_3) {
          let day = values.ExpiryDate_1;
          let month = values.ExpiryDate_2;
          let year = values.ExpiryDate_3;

          if (/^(d{1,2})$/.test(day)) {
            expiryDateError = true;
          }

          if (/^(d{1,2})$/.test(month)) {
            expiryDateError = true;
          }

          if (/^(d{2,4})$/.test(year)) {
            expiryDateError = true;
          }

          if (!year) {
            expiryDateError = true;
          }

          if (year?.toString()?.length <= 3 || year?.toString()?.length > 4) {
            expiryDateError = true;
          }

          if (Number(month) < 1 || Number(month) > 12) {
            expiryDateError = true;
          }

          if (Number(day) < 1 || Number(day) > 31) {
            expiryDateError = true;
          }

          if (
            (Number(month) === 4 ||
              Number(month) === 6 ||
              Number(month) === 9 ||
              Number(month) === 11) &&
            Number(day) === 31
          ) {
            expiryDateError = true;
          }

          if (Number(month) === 2) {
            const isLeapYear =
              (Number(year) % 4 === 0 && Number(year) % 100 !== 0) ||
              Number(year) % 400 === 0;

            if (Number(day) > 29 || (Number(day) === 29 && !isLeapYear)) {
              expiryDateError = true;
            }
          }

          const inputDate = new Date(year + "-" + month + "-" + day);
          const currentDate = new Date();
          const allowedDate = new Date(
            currentDate.setDate(currentDate.getDate() - 1),
          );
          if (inputDate <= allowedDate) {
            expiryDateFutureError = true;
          }
        }

        if (otherBatchNumberRequiredError && expiryDateErrorRequired) {
          setOtherBatchNumberRequired(true);
          setBatchExpiryDateRequiredError(true);
          return;
        }

        if (otherBatchNumberRequiredError && expiryDateError) {
          setOtherBatchNumberRequired(true);
          setBatchExpiryDateError(true);
          return;
        }

        if (otherBatchNumberRequiredError && expiryDateFutureError) {
          setOtherBatchNumberRequired(true);
          setFutureExpiryDateError(true);
          return;
        }

        if (otherBatchNumberRequiredError) {
          setOtherBatchNumberRequired(true);
          return;
        }

        if (expiryDateErrorRequired) {
          setBatchExpiryDateRequiredError(true);
          return;
        }

        if (expiryDateError) {
          setBatchExpiryDateError(true);
          return;
        }

        if (expiryDateFutureError) {
          setFutureExpiryDateError(true);
          return;
        }

        if (
          values.OtherBatchNumber &&
          values.OtherBatchNumber.toUpperCase() !== "UNDEFINED"
        ) {
          batchData.BatchNumber = values.OtherBatchNumber;
        } else {
          setOtherBatchNumberRequired(true);
          return;
        }

        batchData.ExpiryDate =
          values.ExpiryDate_3 +
          "-" +
          values.ExpiryDate_2 +
          "-" +
          values.ExpiryDate_1;

        setBatch(batchData);

        if (addOrEdit && addOrEdit?.IsEditBatch) {
          //perform api edit update
          var returnedResult =
            await siteVaccinesService.editSiteVaccineBatch$(batchData);

          setSiteVaccines(returnedResult.SitesVaccines);
          let updatedOptions = options;
          updatedOptions.Sites = returnedResult.Sites;
          setOptions((prevState) => ({
            ...prevState,
            Sites: returnedResult.Sites,
          }));
          navigate(SiteVaccinesPaths.SiteVaccineBatchesList, {
            state: [
              updatedOptions,
              returnedResult.Sites,
              returnedResult.SitesVaccines,
              returnedResult.SitesVaccines.find(
                (sv) => sv.SiteVaccineId === siteVaccine.SiteVaccineId,
              ) as SiteVaccines,
            ],
          });
        } else {
          //continue to confirmPage (add)
          navigate(SiteVaccinesPaths.ConfirmSiteVaccineBatch, {
            state: [
              options,
              userSites,
              siteVaccines,
              batchData,
              siteVaccineBatches,
              addOrEdit,
              null,
              siteVaccine,
            ],
          });
        }
      }
    },
  });

  React.useEffect(() => {
    if (batch) {
      const checkAddressExists = async () => {
        if (!batch.AddrLn1) {
          let orgDetails = await siteVaccinesService.nhsdOrganisation$(
            batch.Code,
          );

          setBatch((prevState) => ({
            ...prevState,
            AddrLn1: orgDetails?.AddressLine1,
            AddrLn2: orgDetails?.AddressLine2,
            Town: orgDetails?.Town,
            County: orgDetails?.County,
            PostCode: orgDetails?.PostCode,
          }));
        }
      };

      checkAddressExists();
    }
  }, [batch]);

  const handleBatchNumberChange = (e, data) => {
    setBatchExistsError(false);
    setOtherBatchNumberRequired(false);

    let otherBatchNumber = formik.values.OtherBatchNumber;

    otherBatchNumber = e.target?.value;
    const vaccineId = batch?.VaccineId;
    const vaccineProgramId = batch?.VaccineProgramId;

    //add journey from 'Add batch' button in SiteVaccinesList or site-vaccine-batches page
    if (
      (addOrEdit?.IsAddBatchFromSiteVaccinesList ||
        addOrEdit?.IsAddBatchFromSiteBatchesList ||
        addOrEdit?.IsEditBatch) &&
      otherBatchNumber &&
      vaccineProgramId.toString() !==
        SiteVaccinesContants.COVID19_ID.toString() &&
      siteVaccineBatches?.length > 0
    ) {
      CheckOtherBatchNumberExistsInSite(
        batch?.VaccineId,
        batch?.Site,
        otherBatchNumber,
        siteVaccineBatches,
      );
    }
    //add journey from 'Add vaccine' button in SiteVaccinesList page
    if (
      ((addOrEdit?.IsAddBatch &&
        !addOrEdit?.IsAddBatchFromSiteVaccinesList &&
        !addOrEdit?.IsAddBatchFromSiteBatchesList) ||
        addOrEdit?.IsEditBatch) &&
      otherBatchNumber &&
      vaccineProgramId.toString() !==
        SiteVaccinesContants.COVID19_ID.toString() &&
      siteVaccines?.length > 0
    ) {
      siteVaccines
        ?.filter((sv) => sv.VaccineProgramId === vaccineProgramId)
        .forEach((siteVaccine: SiteVaccines) => {
          CheckOtherBatchNumberExistsInSite(
            batch?.VaccineId,
            batch?.Site,
            otherBatchNumber,
            siteVaccine?.SiteVaccineBatches,
          );
        });
    }
  };

  const invalidBatchNumberFormatWarning = (
    <>
      {showInvalidBatchFormatWarning ? (
        <div className="nhsuk-warning-callout">
          <h3 className="nhsuk-warning-callout__label">
            Warning <span className="nhsuk-u-visually-hidden">:</span>
          </h3>
          <p>Batch number doesn't conform to the expected format.</p>
        </div>
      ) : (
        ""
      )}
    </>
  );

  const onFieldChange = (e) => {
    setBatchExpiryDateRequiredError(false);
    setBatchExpiryDateError(false);
    setFutureExpiryDateError(false);
  };

  return (
    <>
      {location && location.state ? (
        <>
          {!addOrEdit ? (
            <>
              <div className="nhsuk-back-link">
                <Link
                  className="nhsuk-back-link__link"
                  to={{ pathname: SiteVaccinesPaths.AddVaccine }}
                  state={[options, userSites, siteVaccines, batch]}
                >
                  <svg
                    className="nhsuk-icon nhsuk-icon__chevron-left"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    height="24"
                    width="24"
                  >
                    <path d="M8.5 12c0-.3.1-.5.3-.7l5-5c.4-.4 1-.4 1.4 0s.4 1 0 1.4L10.9 12l4.3 4.3c.4.4.4 1 0 1.4s-1 .4-1.4 0l-5-5c-.2-.2-.3-.4-.3-.7z"></path>
                  </svg>{" "}
                  Back
                </Link>
              </div>
            </>
          ) : (
            <>
              {addOrEdit?.IsAddEditBatchFromSiteBatchesList === true ? (
                <div className="nhsuk-back-link">
                  <Link
                    className="nhsuk-back-link__link"
                    to={{ pathname: SiteVaccinesPaths.SiteVaccineBatchesList }}
                    state={[options, userSites, siteVaccines, siteVaccine]}
                  >
                    <svg
                      className="nhsuk-icon nhsuk-icon__chevron-left"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      height="24"
                      width="24"
                    >
                      <path d="M8.5 12c0-.3.1-.5.3-.7l5-5c.4-.4 1-.4 1.4 0s.4 1 0 1.4L10.9 12l4.3 4.3c.4.4.4 1 0 1.4s-1 .4-1.4 0l-5-5c-.2-.2-.3-.4-.3-.7z"></path>
                    </svg>{" "}
                    Back
                  </Link>
                </div>
              ) : (
                <>
                  {addOrEdit?.IsAddEditBatchFromSiteVaccinesList === true && (
                    <div className="nhsuk-back-link">
                      <Link
                        className="nhsuk-back-link__link"
                        to={{ pathname: SiteVaccinesPaths.SiteVaccinesList }}
                        state={[null, [] as SiteVaccines[]]}
                      >
                        <svg
                          className="nhsuk-icon nhsuk-icon__chevron-left"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          height="24"
                          width="24"
                        >
                          <path d="M8.5 12c0-.3.1-.5.3-.7l5-5c.4-.4 1-.4 1.4 0s.4 1 0 1.4L10.9 12l4.3 4.3c.4.4.4 1 0 1.4s-1 .4-1.4 0l-5-5c-.2-.2-.3-.4-.3-.7z"></path>
                        </svg>{" "}
                        Back
                      </Link>
                    </div>
                  )}
                </>
              )}
            </>
          )}

          <div className="nhsuk-grid-row mt-3">
            <div className="nhsuk-grid-column-two-thirds">
              {(otherBatchNumberRequired ||
                batchExpiryDateRequiredError ||
                batchExpiryDateError ||
                futureExpiryDateError ||
                batchExistsError) && (
                <>
                  <div
                    className="nhsuk-error-summary"
                    aria-labelledby="error-summary-title"
                    role="alert"
                  >
                    <h2
                      className="nhsuk-error-summary__title"
                      id="error-summary-title"
                    >
                      There is a problem
                    </h2>
                    <div className="nhsuk-error-summary__body">
                      <ul className="nhsuk-list nhsuk-error-summary__list">
                        {batchExistsError && showOtherBatchNumberField && (
                          <li className="nhsuk-error-message">
                            <Link
                              to="#"
                              onClick={(e) => {
                                HandleErrorFocusOnClickByName(
                                  e,
                                  "OtherBatchNumber",
                                );
                              }}
                            >
                              Batch number already exists
                            </Link>
                          </li>
                        )}

                        {otherBatchNumberRequired && (
                          <li className="nhsuk-error-message">
                            <Link
                              to="#"
                              onClick={(e) => {
                                HandleErrorFocusOnClickByName(
                                  e,
                                  "OtherBatchNumber",
                                );
                              }}
                            >
                              Enter the batch number
                            </Link>
                          </li>
                        )}

                        {batchExpiryDateRequiredError &&
                          showOtherBatchNumberField && (
                            <li className="nhsuk-error-message">
                              <Link
                                to="#"
                                onClick={(e) => {
                                  HandleErrorFocusOnClickByName(
                                    e,
                                    "ExpiryDate_1",
                                  );
                                }}
                              >
                                Enter the expiry date
                              </Link>
                            </li>
                          )}

                        {batchExpiryDateError && showOtherBatchNumberField && (
                          <li className="nhsuk-error-message">
                            <Link
                              to="#"
                              onClick={(e) => {
                                HandleErrorFocusOnClickByName(
                                  e,
                                  "ExpiryDate_1",
                                );
                              }}
                            >
                              Enter the expiry date in the correct format
                            </Link>
                          </li>
                        )}

                        {futureExpiryDateError && showOtherBatchNumberField && (
                          <li className="nhsuk-error-message">
                            <Link
                              to="#"
                              onClick={(e) => {
                                HandleErrorFocusOnClickByName(
                                  e,
                                  "ExpiryDate_3",
                                );
                              }}
                            >
                              Expiry date must be in the future
                            </Link>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </>
              )}

              <form onSubmit={formik.handleSubmit}>
                <fieldset className="nhsuk-fieldset mb-3">
                  <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl">
                    <h1 className="nhsuk-fieldset__heading">
                      {addOrEdit?.IsEditBatch ? "Edit batch" : "Add batch"}
                    </h1>
                  </legend>
                </fieldset>

                {showOtherBatchNumberField ? (
                  <>
                    <div
                      className={
                        "nhsuk-form-group " +
                        (otherBatchNumberRequired || batchExistsError
                          ? "nhsuk-form-group--error"
                          : "")
                      }
                    >
                      {invalidBatchNumberFormatWarning}

                      <label className="nhsuk-label" htmlFor="OtherBatchNumber">
                        Batch number
                      </label>

                      {otherBatchNumberRequired ? (
                        <span
                          key="OtherBatchNumberIdError"
                          className="nhsuk-error-message"
                          id="OtherBatchNumberIdError"
                        >
                          <span
                            key="OtherBatchNumberIdVisuallyHiddenError"
                            className="nhsuk-u-visually-hidden"
                          >
                            Error:
                          </span>{" "}
                          Enter the batch number
                        </span>
                      ) : (
                        ""
                      )}

                      {batchExistsError ? (
                        <span
                          key="OtherBatchNumberAlreadyExistsError"
                          className="nhsuk-error-message"
                          id="OtherBatchNumberAlreadyExistsIdError"
                        >
                          <span
                            key="OtherBatchNumberAlreadyExistsVisuallyHiddenError"
                            className="nhsuk-u-visually-hidden"
                          >
                            Error:
                          </span>{" "}
                          Batch number already exists
                        </span>
                      ) : (
                        ""
                      )}

                      <input
                        className={
                          "nhsuk-input nhsuk-input--width-10 " +
                          (otherBatchNumberRequired || batchExistsError
                            ? "nhsuk-input--error"
                            : "")
                        }
                        id="OtherBatchNumber"
                        name="OtherBatchNumber"
                        value={
                          formik.values.OtherBatchNumber === "UNDEFINED"
                            ? ""
                            : formik.values?.OtherBatchNumber === ""
                              ? ""
                              : formik.values?.OtherBatchNumber
                        }
                        type="text"
                        onInput={(e) => {
                          const target = e.target as HTMLTextAreaElement;
                          target.value = ("" + target.value).toUpperCase();
                        }}
                        onChange={(e) => {
                          handleBatchNumberChange(e, {
                            IsCovidBatchNumberPrefix: false,
                            IsOtherBatchNumber: true,
                            IsCovidBatchNumberSuffix: false,
                          });
                          formik.handleChange(e);
                        }}
                        onBlur={(e) => {
                          handleBatchNumberChange(e, {
                            IsCovidBatchNumberPrefix: false,
                            IsOtherBatchNumber: true,
                            IsCovidBatchNumberSuffix: false,
                          });
                          formik.handleBlur(e);
                        }}
                      />
                    </div>
                  </>
                ) : (
                  ""
                )}

                {showOtherBatchNumberField ? (
                  <>
                    <div
                      className={
                        "nhsuk-form-group " +
                        (batchExpiryDateRequiredError ||
                        batchExpiryDateError ||
                        futureExpiryDateError
                          ? "nhsuk-form-group--error"
                          : "")
                      }
                    >
                      <hr></hr>
                      <fieldset
                        className="nhsuk-fieldset"
                        aria-describedby="ExpiryDate-hint"
                        role="group"
                      >
                        {showOtherBatchNumberField && (
                          <legend className="nhsuk-fieldset__legend">
                            Expiry date
                          </legend>
                        )}
                        <div className="nhsuk-hint" id="ExpiryDate-hint">
                          For example, 31 03 {new Date().getFullYear() + 1}
                        </div>

                        <div className="nhsuk-date-input" id="ExpiryDate">
                          {batchExpiryDateRequiredError &&
                            showOtherBatchNumberField && (
                              <span
                                key="ExpiryDateErrorRequiredSpan"
                                className="nhsuk-error-message"
                                id="ExpiryDateErrorRequiredId"
                              >
                                <span
                                  key="ExpiryDateErrorRequiredSpanHidden"
                                  className="nhsuk-u-visually-hidden"
                                >
                                  Error:
                                </span>{" "}
                                Enter the expiry date
                              </span>
                            )}

                          {batchExpiryDateError &&
                            showOtherBatchNumberField && (
                              <span
                                key="ExpiryDateErrorSpan"
                                className="nhsuk-error-message"
                                id="ExpiryDateErrorId"
                              >
                                <span
                                  key="ExpiryDateErrorSpanHidden"
                                  className="nhsuk-u-visually-hidden"
                                >
                                  Error:
                                </span>{" "}
                                Enter the expiry date in the correct format
                              </span>
                            )}

                          {futureExpiryDateError &&
                            showOtherBatchNumberField && (
                              <span
                                key="ExpiryDateFutureErrorSpan"
                                className="nhsuk-error-message"
                                id="ExpiryDateFutureErrorId"
                              >
                                <span
                                  key="ExpiryDateFutureErrorSpanHidden"
                                  className="nhsuk-u-visually-hidden"
                                >
                                  Error:
                                </span>{" "}
                                Expiry date must be in the future
                              </span>
                            )}

                          <div className="nhsuk-date-input__item">
                            <div className="nhsuk-form-group">
                              <label
                                className="nhsuk-label nhsuk-date-input__label"
                                htmlFor="ExpiryDate_1"
                              >
                                Day
                              </label>

                              <input
                                className={
                                  "nhsuk-input nhsuk-date-input__input nhsuk-input--width-2 " +
                                  (batchExpiryDateRequiredError ||
                                  batchExpiryDateError ||
                                  futureExpiryDateError
                                    ? "nhsuk-input--error"
                                    : "")
                                }
                                id="ExpiryDate_1"
                                name="ExpiryDate_1"
                                type="number"
                                inputMode="numeric"
                                value={formik.values.ExpiryDate_1 || ""}
                                placeholder="DD"
                                maxLength={2}
                                onChange={(e) => {
                                  onFieldChange(e);
                                  formik.handleChange(e);
                                }}
                                onBlur={(e) => {
                                  onFieldChange(e);
                                  formik.handleBlur(e);
                                }}
                              />
                            </div>
                          </div>
                          <div className="nhsuk-date-input__item">
                            <div className="nhsuk-form-group">
                              <label
                                className="nhsuk-label nhsuk-date-input__label"
                                htmlFor="ExpiryDate_2"
                              >
                                Month
                              </label>

                              <input
                                className={
                                  "nhsuk-input nhsuk-date-input__input nhsuk-input--width-2 " +
                                  (batchExpiryDateRequiredError ||
                                  batchExpiryDateError ||
                                  futureExpiryDateError
                                    ? "nhsuk-input--error"
                                    : "")
                                }
                                id="ExpiryDate_2"
                                name="ExpiryDate_2"
                                type="number"
                                inputMode="numeric"
                                value={formik.values.ExpiryDate_2 || ""}
                                placeholder="MM"
                                maxLength={2}
                                onChange={(e) => {
                                  onFieldChange(e);
                                  formik.handleChange(e);
                                }}
                                onBlur={(e) => {
                                  onFieldChange(e);
                                  formik.handleBlur(e);
                                }}
                              />
                            </div>
                          </div>
                          <div className="nhsuk-date-input__item">
                            <div className="nhsuk-form-group">
                              <label
                                className="nhsuk-label nhsuk-date-input__label"
                                htmlFor="ExpiryDate_3"
                              >
                                Year
                              </label>

                              <input
                                className={
                                  "nhsuk-input nhsuk-date-input__input nhsuk-input--width-4 " +
                                  (batchExpiryDateRequiredError ||
                                  batchExpiryDateError ||
                                  futureExpiryDateError
                                    ? "nhsuk-input--error"
                                    : "")
                                }
                                id="ExpiryDate_3"
                                name="ExpiryDate_3"
                                type="number"
                                inputMode="numeric"
                                value={formik.values.ExpiryDate_3 || ""}
                                placeholder="YYYY"
                                maxLength={4}
                                onChange={(e) => {
                                  onFieldChange(e);
                                  formik.handleChange(e);
                                }}
                                onBlur={(e) => {
                                  onFieldChange(e);
                                  formik.handleBlur(e);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </>
                ) : (
                  ""
                )}

                <div className="mt-3">
                  <Button type="submit" className="nhsuk-button">
                    {addOrEdit && addOrEdit?.IsEditBatch ? (
                      <> Confirm</>
                    ) : (
                      <>Continue</>
                    )}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </>
      ) : (
        redirect()
      )}
    </>
  );
}
