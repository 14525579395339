import React, { useEffect, useState } from "react";
import userService from "../user.service";
import useAnalytics from "../../analytics/hooks/useAnalytics";
import useDocumentTitle from "../../../_shared/hooks/useDocumentTitle";
import {
  RoleIds,
  UserPageTitles,
  UserProfilePrimaryCategory,
} from "../user.enums";
import { NhsdOrganisation, Option } from "../../../_shared/shared.models";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { BackLink, TextInput } from "nhsuk-react-components";
import { Link } from "react-router-dom";
import { ProfileUserName, User } from "../user.models";
import organisationService from "../../../_shared/services/organisation/organisation.service";
import regionalService from "../../../_shared/services/regional/regional.service";
import { matchRoleIdWithRole } from "./ManageUsersRoot";

interface UserNameFormValues {
  firstName: string;
  lastName: string;
}

export default function Profile() {
  useAnalytics(["service", UserProfilePrimaryCategory.PrimaryCategory]);
  useDocumentTitle(UserPageTitles.UserProfile);
  const [user, setUser] = useState<User | null>(null);
  const [leadAdmins, setLeadAdmins] = useState(null);
  const [showUpdateNameForm, setShowUpdateNameForm] = useState(false);
  const [organisation, setOrganisation] = useState<
    NhsdOrganisation | undefined
  >(undefined);

  useEffect(() => {
    if (user?.OrganisationId) {
      fetchOrganisation();
    }
  }, [user?.OrganisationId]);

  const fetchOrganisation = async () => {
    try {
      const organisation = await organisationService.getOrganisation$(
        user?.OrganisationId,
      );

      if (organisation?.Code) {
        const results = await regionalService.searchOrganisations$(
          organisation.Code,
          true,
        );

        if (results && results.length > 0) {
          setOrganisation(results[0]);
        }
      }
    } catch (error) {
      console.error(
        "Error fetching organisation or searching organisations:",
        error,
      );
    }
  };

  useEffect(() => {
    const subscription = userService.subscribe(() => populateState());
    populateState();
    return () => {
      userService.unsubscribe(subscription);
    };
  }, []);

  const populateState = async () => {
    Promise.resolve(userService.getUser$()).then((loggedUser) => {
      if (loggedUser) {
        setUser(loggedUser);
        if (
          !loggedUser.Roles.includes(RoleIds.LeadAdministrator) &&
          user?.OrganisationId
        ) {
          Promise.resolve(userService.getLeadAdmins$()).then((admins) => {
            setLeadAdmins(admins);
          });
        }
      }
    });
  };

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .required("Enter first name")
      .max(50, "First name must be 50 characters or less"),
    lastName: Yup.string()
      .required("Enter last name")
      .max(50, "Last name must be 50 characters or less"),
  });

  const handleBackClick = () => {
    setShowUpdateNameForm(false);
  };

  function roleHintList(): Option[] {
    return [
      {
        Id: RoleIds.Administrator,
        Name: "Can record vaccinations, create reports and manage vaccines",
      },
      {
        Id: RoleIds.LeadAdministrator,
        Name: "Can record vaccinations, create reports, manage vaccines and users",
      },
      { Id: RoleIds.Recorder, Name: "Can record vaccinations only" },
      { Id: RoleIds.RegionalAdministrator, Name: "Regional administrator" },
    ];
  }

  function matchRoleIdWithHint(roleIds: string[]): React.ReactNode {
    var hints = "";
    roleIds.map((roleId) => {
      hints = roleHintList().filter((x) => x.Id === roleId)[0]?.Name;
    });
    return <>{hints}</>;
  }

  function onSubmit(values: UserNameFormValues) {
    const profileUserName: ProfileUserName = {
      Email: user.Email,
      FirstName: values.firstName,
      LastName: values.lastName,
    };
    userService.updateUserName$(profileUserName).then((result) => {
      if (result != null) {
        userService.refreshUserSessionName(profileUserName);
        setShowUpdateNameForm(false);
      }
    });
  }

  return (
    <div className="nhsuk-grid-row">
      <div className="nhsuk-grid-column-two-thirds">
        <div className="profile-page-container">
          {user
            ? showUpdateNameForm
              ? displayUpdateUserNameForm()
              : displayProfile()
            : null}
        </div>
      </div>
    </div>
  );

  function displayUpdateUserNameForm() {
    return (
      <>
        <BackLink asElement="button" onClick={() => handleBackClick()}>
          Back
        </BackLink>
        <div className="profile-form-container">
          <Formik<UserNameFormValues>
            initialValues={{
              firstName: user.FirstName,
              lastName: user.LastName,
            }}
            validationSchema={validationSchema}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={(values) => onSubmit(values)}
          >
            {({ isSubmitting, errors }) => (
              <Form>
                <fieldset className="nhsuk-fieldset">
                  <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--l">
                    <h1 className="nhsuk-fieldset__heading">
                      Update your name
                    </h1>
                  </legend>

                  <div>
                    <Field name="firstName">
                      {({ field, meta }) => (
                        <TextInput
                          {...field}
                          id="firstName"
                          label="First name"
                          error={meta.touched && meta.error ? meta.error : ""}
                          width={20}
                        />
                      )}
                    </Field>
                  </div>

                  <div>
                    <Field name="lastName">
                      {({ field, meta }) => (
                        <TextInput
                          {...field}
                          id="lastName"
                          label="Last name"
                          error={meta.touched && meta.error ? meta.error : ""}
                          width={20}
                        />
                      )}
                    </Field>
                  </div>
                  <button
                    className="nhsuk-button"
                    data-module="nhsuk-button"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Save and continue
                  </button>
                </fieldset>
              </Form>
            )}
          </Formik>
        </div>
      </>
    );
  }

  function displayProfile() {
    return (
      <>
        <fieldset>
          <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl nhsuk-u-margin-bottom-7">
            <h1 className="nhsuk-fieldset__heading">Your profile</h1>
          </legend>
        </fieldset>

        {user ? (
          <>
            <dl className="nhsuk-summary-list nhsuk-u-margin-bottom-4">
              <div className="nhsuk-summary-list__row">
                <dt className="nhsuk-summary-list__key">Name</dt>
                <dd className="nhsuk-summary-list__value profile-page-summary-list-value">
                  {user.FirstName} {user.LastName}
                </dd>
                <dd className="nhsuk-summary-list__actions">
                  <span className="nhsuk-u-visually-hidden"></span>
                </dd>
                {/* Ravs - 666 on hold */}
                {/* <dd className="nhsuk-summary-list__actions">
                                    <button
                                        className="nhs-link-button"
                                        onClick={() => {
                                            setShowUpdateNameForm(true);
                                        }}
                                    >
                                        Change
                                        <span className="nhsuk-u-visually-hidden">name</span>
                                    </button>
                                </dd> */}
              </div>

              <div className="nhsuk-summary-list__row">
                <dt className="nhsuk-summary-list__key">Email address</dt>
                <dd className="nhsuk-summary-list__value">{user.Email}</dd>
                <dd className="nhsuk-summary-list__actions">
                  <span className="nhsuk-u-visually-hidden"></span>
                </dd>
              </div>

              <div className="nhsuk-summary-list__row">
                <dt className="nhsuk-summary-list__key">Organisation</dt>
                <dd className="nhsuk-summary-list__value">
                  {user.Organisation}
                  {organisation && (
                    <div className="nhsuk-hint">
                      {organisation?.AddressLine1}
                      <br />
                      {organisation?.Town}
                      <br />
                      {organisation?.PostCode}
                    </div>
                  )}
                </dd>
                <dd className="nhsuk-summary-list__actions">
                  <span className="nhsuk-u-visually-hidden"></span>
                </dd>
              </div>

              <div className="nhsuk-summary-list__row">
                <dt className="nhsuk-summary-list__key">Permission level</dt>
                <dd className="nhsuk-summary-list__value">
                  {matchRoleIdWithRole(user.Roles)}
                  <div className="nhsuk-hint">
                    {matchRoleIdWithHint(user.Roles)}
                  </div>
                </dd>
                <dd className="nhsuk-summary-list__actions">
                  <span className="nhsuk-u-visually-hidden"></span>
                </dd>
              </div>
            </dl>

            {leadAdmins && leadAdmins.data.length > 0 ? (
              <>
                {leadAdmins.data.length === 1 ? (
                  <p>
                    Only lead administrators can change permission levels. The
                    lead administrator for {user.Organisation} is{" "}
                    {leadAdmins.data.map((x) => (
                      <span key={x.UserId}>
                        {" "}
                        {x.FirstName} {x.LastName} ({x.Email})
                      </span>
                    ))}
                  </p>
                ) : null}

                {leadAdmins.data.length > 1 ? (
                  <>
                    <p>
                      Only lead administrators can change permission levels. The
                      lead administrators for {user.Organisation} are:{" "}
                    </p>
                    <ul className="nhsuk-list nhsuk-list--bullet">
                      {leadAdmins.data.map((x) => (
                        <li className="text-wrap-pretty" key={x.UserId}>
                          {x.FirstName} {x.LastName} ({x.Email})
                        </li>
                      ))}
                    </ul>
                  </>
                ) : null}
              </>
            ) : null}
            <p>
              If you need to change your email address,{" "}
              <Link to={"/contact-us"} className="nhsuk-link">
                contact support
              </Link>
            </p>
          </>
        ) : (
          <div className="pt-4 pb-4 background-white">
            <div className="alert alert-primary" role="alert">
              Not signed in!
            </div>
          </div>
        )}
      </>
    );
  }
}
